import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { useForm, Controller } from "react-hook-form";
import Header from "../components/header";
import Breadcrumbs from "../components/breadcrumbs";
import EventCard from "../components/event-card";
import CalendarSearch from "../components/calendar-search";
import { placeholders } from "../utils/admin";
import Seo from "../components/seo";

// import scaricaProgramma from "../assets/scarica-programma.jpg";
import { FESTIVAL_DAY } from "../utils/constants";
import { usePage, useSearch } from "../hooks/useQueryCustom";
import PageContent from "../components/page-content";

const ProgrammaExtra = ({ page, breadcrumbs }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const resultSection = useRef();
  const emptyValue = {
    dt_r: "",
  };
  const { data } = usePage(page.link.slice(1));
  const { data: results } = useSearch(searchParams, "3");

  const { handleSubmit, watch, control, register } = useForm({
    defaultValues: async () => {
      let defaults = Object.fromEntries(searchParams);
      return { ...emptyValue, ...defaults };
    },
  });
  const watchDay = watch("dt_r");
  const currentPage = searchParams.get("from");
  const currentdt_r = searchParams.get("dt_r");

  const gotoPage = (page) => {
    console.log("gotoPage", page);
    const params = new URLSearchParams(searchParams);
    if (page > 0) {
      params.set("from", page * 12);
    } else {
      params.delete("from");
    }
    setSearchParams(params);
  };

  useEffect(() => {
    currentPage && resultSection.current.scrollIntoView();
  }, [currentPage]);

  const dayRef = useRef(currentdt_r);
  useEffect(() => {
    if (watchDay && watchDay !== dayRef.current) {
      const params = new URLSearchParams(searchParams);
      params.delete("from");
      params.set("dt_r", watchDay);
      setSearchParams(params);
    }
  }, [searchParams, setSearchParams, watchDay]);

  const onSubmit = (variables) => {
    const params = new URLSearchParams();
    for (const key in variables) {
      variables[key] && params.set(key, variables[key]);
    }
    params.delete("from");
    setSearchParams(params);
  };

  return (
    <>
      <Seo title={page.name} />
      <Header data={breadcrumbs} />
      {breadcrumbs && <Breadcrumbs data={breadcrumbs} />}
      <div role="main" className="main-wrapper  mt-3" id="main">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="programma-scuola__logo d-flex flex-column flex-md-row  justify-content-between align-items-center">
                <h1>{data?.titoloInPagina || data?.titolo}</h1>
              </div>
              <PageContent data={data} />
            </div>
          </div>
        </div>
        <form name="search-event-scuole" onSubmit={handleSubmit(onSubmit)}>
          <div className="container-md">
            <div className="row">
              <div className="col-12">
                <Controller
                  control={control}
                  name="dt_r"
                  render={({ field: { onChange, value } }) => (
                    <CalendarSearch
                      data={FESTIVAL_DAY}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="search-event py-4 mt-4">
            <div className="container-md">
              <div className="row">
                <div className="col-6">
                  <div className="w-100">
                    <input
                      className="search-event__input"
                      placeholder="Cerca nel titolo"
                      {...register("t_r", { required: false })}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-2">
                  <button className="search-event__submit" type="submit">
                    Cerca
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div ref={resultSection} />
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="search-event__result-number my-4">
                {results?.nrows} risultati{" "}
                {results?.current > 1 && ` - pagina ${results.current}`}
              </div>
              <div className="row">
                {(results?.rows || placeholders(6)).map((item, index) => (
                  <div key={index} className="col-sm-6 col-lg-4">
                    <EventCard data={item} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="search-event__pagination d-flex flex-wrap gap-2 justify-content-md-end">
                {results?.npages?.map((item, index) => (
                  <button
                    className={classNames(
                      "",
                      results.current === index && "active"
                    )}
                    key={index}
                    onClick={() => gotoPage(index)}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProgrammaExtra;
