import { useContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import UserContext from "../context/user";
import {
  getArticle,
  getEdizioni,
  getFasciaArticoli,
  getFasciaDidattica,
  getFasciaPersone,
  getHome,
  getNavigation,
  getNews,
  getPage,
  getSponsor,
} from "../utils/cms";
import {
  getRelatori,
  getLuoghi,
  search,
  getEvent,
  getBooking,
  getProtagonisti,
  getEventSlugById,
} from "../utils/admin";
import { useNavigate } from "react-router-dom";
import { useNavigazione } from "../context/navigazione";

export const useNavigation = () => {
  const { isAdmin } = useContext(UserContext);
  return useQuery({
    queryKey: ["navigation", isAdmin],
    queryFn: () => getNavigation(isAdmin),
    select: (data) => {
      //console.log(data);
      return data;
    },
  });
};

export const useNews = () => {
  return useQuery({
    queryKey: ["news"],
    queryFn: () => getNews(),
    select: (data) => {
      //console.log(data);
      return data;
    },
    staleTime: 0,
  });
};

export const useEdizioni = () => {
  return useQuery({
    queryKey: ["edizioni"],
    queryFn: () => getEdizioni(),
    select: (data) => {
      //console.log(data);
      return data;
    },
  });
};

export const useRelatori = () => {
  return useQuery({
    queryKey: ["relatori"],
    queryFn: () => getRelatori(),
    select: (data) => {
      return data.relatori;
    },
  });
};

export const useProtagonisti = (letter) => {
  return useQuery({
    queryKey: ["relatori", letter],
    queryFn: () => getProtagonisti(letter),
    select: (data) => {
      if (Array.isArray(data?.relatori)) {
        return data.relatori;
      } else if (data?.relatori) {
        return [data.relatori];
      }
    },
  });
};

export const useLuoghi = () => {
  return useQuery({
    queryKey: ["luoghi"],
    queryFn: () => getLuoghi(),
    select: (data) => {
      const macroluoghi = data.locations.filter(
        (item) => item.isMacroluogo === "1" // && item.id !== "525"
      );
      return macroluoghi;
    },
  });
};

export const useEventId = (id) => {
  return useQuery({
    queryKey: ["event-id", id],
    queryFn: ({ queryKey }) => getEventSlugById(queryKey[1]),
    enabled: !!id,
  });
};

const useEventSlug = (slug) => {
  return useQuery({
    queryKey: ["event", slug],
    queryFn: ({ queryKey }) => getEvent(queryKey[1]),
    enabled: !!slug,
  });
};

export const useEvent = (path) => {
  const eventIdentifier = path?.startsWith("id-") ? path.slice(3) : path;
  const isId = !isNaN(eventIdentifier);
  const { data: slug, isFetched } = useEventId(isId ? eventIdentifier : null);
  const eventQueryResult = useEventSlug(isId ? null : eventIdentifier);
  const { getPathByTemplate } = useNavigazione();
  const programmaPath = getPathByTemplate("programma");
  const navigate = useNavigate();
  useEffect(() => {
    if (slug) {
      navigate(`${programmaPath}/${slug}`);
    }
  }, [navigate, programmaPath, slug]);
  return {
    ...eventQueryResult,
    isFetched: eventQueryResult.isFetched || isFetched,
  };
};

export const useBooking = (data, enabled) => {
  const { isAmico } = useContext(UserContext);
  const id = data?.idevento["#text"];
  return useQuery({
    queryKey: ["booking", id, isAmico],
    queryFn: () => getBooking(id, isAmico),
    enabled: enabled,
    staleTime: 0,
  });
};

export const useSearch = (params, sezionefe) => {
  return useQuery({
    queryKey: ["search", params.toString(), sezionefe],
    queryFn: () => search(params, sezionefe),
    select: (data) => {
      if (data.nrows === 0) {
        data.rows = [];
      } else {
        const from = Number(params.get("from"));
        const npages = Math.ceil(Number(data.nrows) / 12);
        data.npages = Array(npages).fill("");
        data.current = (from + 12) / 12 - 1;
      }
      /* console.log(
        data.rows.reduce((acc, value, index) => {
          return [...acc, value.id];
        }, [])
      ); */
      return data;
    },
  });
};

export const useHome = () => {
  return useQuery({
    queryKey: ["homepage"],
    queryFn: () => getHome(),
  });
};

export const usePage = (slug) => {
  return useQuery({
    queryKey: ["page", slug],
    queryFn: ({ queryKey }) => getPage(queryKey[1]),
  });
};

export const useArticle = (slug) => {
  return useQuery({
    queryKey: ["article", slug],
    queryFn: ({ queryKey }) => getArticle(queryKey[1]),
  });
};

export const useSponsor = () => {
  return useQuery({
    queryKey: ["sponsor"],
    queryFn: () => getSponsor(),
  });
};

export const useFasciaArticoli = (id) => {
  return useQuery({
    queryKey: ["fasciaArticoli", id],
    queryFn: ({ queryKey }) => getFasciaArticoli(queryKey[1]),
  });
};

export const useFasciaDidattica = (id) => {
  return useQuery({
    queryKey: ["fasciaDidattica", id],
    queryFn: ({ queryKey }) => getFasciaDidattica(queryKey[1]),
  });
};

export const useFasciaPersone = (id) => {
  return useQuery({
    queryKey: ["fasciaPersone", id],
    queryFn: ({ queryKey }) => getFasciaPersone(queryKey[1]),
  });
};
