import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Icon from "../components/icons";
import UserContext from "../context/user";
import { useNavigazione } from "../context/navigazione";

const UserMenu = () => {
  const [menuUserOpen, setMenuUserOpen] = useState(false);
  const { logout, isLogged } = useContext(UserContext);
  const { getPathByTemplate } = useNavigazione();
  const linkCarrello = getPathByTemplate("carrello");
  const linkAreaClienti = getPathByTemplate("areaClienti");
  return isLogged ? (
    <>
      <span className="btn-account position-relative">
        <button
          onClick={() => setMenuUserOpen(!menuUserOpen)}
          //onMouseOver={() => setMenuUserOpen(true)}
          title="Account"
          type="button"
        >
          <Icon name="user" />
          <Icon name="angle-down" className="small" />
        </button>
        {menuUserOpen && (
          <div
            className="shopping-menu"
            //onMouseLeave={() => setMenuUserOpen(false)}
          >
            <ul className="shopping-menu__list">
              <li className="shopping-menu__item shopping-menu__item--area-clienti">
                <Link
                  className="shopping-menu__link"
                  title="Biglietti e prenotazioni"
                  to={linkAreaClienti}
                >
                  Area clienti
                </Link>
              </li>
              <li className="shopping-menu__item">
                <Link className="shopping-menu__link" to="/profilo">
                  Il tuo profilo
                </Link>
              </li>
              <li className="shopping-menu__item">
                <button className="shopping-menu__link" onClick={logout}>
                  Esci
                </button>
              </li>
            </ul>
          </div>
        )}
      </span>

      <span className="btn-carrello mx-4">
        <Link className="btn" title="Carrello" to={linkCarrello}>
          <Icon name="shopping-cart" />
        </Link>
      </span>
    </>
  ) : (
    <span className="btn-account me-4">
      <Link to={linkAreaClienti}>
        <Icon name="user" />
      </Link>
    </span>
  );
};

export default UserMenu;
